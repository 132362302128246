body {
    margin: 0;
    overflow: hidden;
}

#panorama {
    width: 100vw;
    height: 100vh;
}

#minimap {
    position: absolute;
    bottom: 5px;
    left: 0px;
    width: 400px;
    height: 400px;
    opacity: 0.2;
    background-color: rgba(0, 0, 0, 0.3);
    transition: 0.5s;
}
#minimap:hover {
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
}
